body {
  background: #F7F3F0;
  color: #262626;

  font-family: 'UniversLTPro-75Black', sans-serif;
  font-size: 18px;
  margin: 15px;
}

#wrap {
  max-width: 555px;
  margin-bottom: 100px;
}

#abstract {
  width: 100%;
}

#cta {
  text-align: right;
  text-transform: uppercase;
  text-transform: uppercase;
  color: #000;
  font-family: "acumin-pro", sans-serif;
  margin: 0 0 30px;
}

p {
  margin: 0 0 50px;
  line-height: 130%;
  letter-spacing: -0.01em;
}

#end p {
  margin: 0 0 20px;
}

h1 {
  margin: 0 0 40px;
  font-size: 60px;
  line-height: 86%;
  letter-spacing: -0.04em;
  color: #000;
  font-weight: 900;
  font-family: "acumin-pro", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
}

object, img {
  margin: 0 auto 40px;
  width: 100%;
  max-width: 600px;
  display: block;
}

h2 {
  margin: 0 0 20px;
  font-size: 40px;
  font-weight: 900;
  line-height: 86%;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  color: #000;
  font-family: "acumin-pro", sans-serif;
  hyphens: manual;
}

h3 {
  margin: 150px 0;
  font-size: 18px;
  font-weight: 900;
  line-height: 86%;
  text-transform: uppercase;
  color: #000;
  font-family: "acumin-pro", sans-serif;
}

a:link, a:visited {
  color: #000;
}

a:hover, a:active {
  color: #000;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul li {
  margin: 0 0 20px;
  padding: 0;
}

#signatories {
  font-family: "acumin-pro", sans-serif;
  line-height: 86%;
  text-transform: uppercase;
  margin-top: 150px;
}

#signatories h2 {
  margin-bottom: 30px;
}

#signatories li {
  font-size: 20px;
  line-height: 100%;
  margin-bottom: 20px;
}

#signatories li a:link, #signatories li a:visited {
  text-decoration: none;
}

#signatories li a:hover, #signatories li a:active {
  text-decoration: underline;
}

@media screen and (min-width: 555px) {
  body {
    font-size: 22px;
  }

  h1 {
    font-size: 86px;
    margin-bottom: -10px;
  }

  h2 {
    font-size: 66px;
  }

  h3 {
    font-size: 28px;
  }

}